<template>
  <div class="d-flex flex-column fill-height justify-center align-center text-header text-center">
    Pricing page <br> is under construction
  </div>
</template>

<script>
export default {
  name: "PricingLanding",
}
</script>
